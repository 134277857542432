const moment = require('moment-timezone');

const timeZone = 'Asia/Shanghai';
const timeZoneHourDiff = 6;

// 先轉到UTC+8再扣掉6小時，目標時區固定UTC+2但須忽略夏令時間機制
const formatDateTime = (value, formatter) => (value && moment.tz(value, timeZone).subtract(timeZoneHourDiff, 'hours').format(formatter)) || '';

const install = (Vue, options) => {
    Vue.prototype.$dateToUtc = value => (value && moment.tz(value, timeZone).startOf('day').add(timeZoneHourDiff, 'hours').utc().format()) || '';

    Vue.prototype.$formatDateTime = formatDateTime;

    Vue.filter('formatDateTime', formatDateTime);
};

export default install;