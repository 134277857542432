<template>
  <div class="body" v-if="newsDetail">
    <div class="clear location">
      <router-link :to="{name:'Home'}">
        Home
      </router-link>
      &gt;
      <router-link :to="{name:'News'}">
        News
      </router-link>
      &gt; {{newsDetail.title}}
    </div>
    <div class="clear lineX lineMargin"></div>
    <div>
      <b class="b1"></b>
      <b class="b2 d1"></b>
      <b class="b3 d1"></b>
      <b class="b4 d1"></b>
      <div class="b">
        <div class="content">
          <div class="contentLeft2">
            <div class="newsDetail">
              <div class="newsDetailTilte">
                {{newsDetail.title}}
              </div>
              <div class="newsDetailContent" id="newsDetail" v-html="newsDetail.content">
              </div>
            </div>
          </div>
          <div class="contentRight2">
            <div class="newsDetailTilte">
              <div class="left">About News</div>
              <div class="right">
                <router-link :to="{name:'News'}">
                  <img src="../assets/images/more.jpg">
                </router-link>
              </div>
            </div>
            <div class="newsDetailList">
              <li v-for="news in newsList" :key="news.id">
                <router-link :to="{ name:'NewsDetail', params:{ id:news.id }}" :title="news.title">
                  {{news.title}}
                </router-link>
              </li>
              <div class="clear lineX"></div>
            </div>
            <div>
              <a href="http://www.powerball.com/" target="_blank">
                <img src="../assets/images/newsdetail_1.png">
              </a>
            </div>
          </div>
        </div>
      </div>
      <b class="b4b d1"></b>
      <b class="b3b d1"></b>
      <b class="b2b d1"></b>
      <b class="b1b"></b>
    </div>
  </div>

</template>

<script>
import service from '../service';

export default {
  props: {
    id: {
      type: String | Number,
      required: true
    }
  },
  watch: {
    id() {
      this.init();
    }
  },
  data() {
    return {
      newsDetail: null,
      newsList: []
    };
  },
  methods: {
    getNewsDetail() {
      let id = parseInt(this.id);

      if (isNaN(id))
        return;

      this.newsDetail = service.getDetail(id);
    },
    getNewsList() {
      this.newsList = service.getNews(1, 7).data;
    },
    init() {
      this.getNewsDetail();
      this.getNewsList();
    }
  },
  created() {
    this.init();
  }
}
</script>