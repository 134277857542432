<template>
  <div class="body">
    <div id="banner">
      <transition name="fade-banner" mode="out-in">
        <template v-for="banner in banners">
          <router-link custom :to="{ name:banner.toName }" v-slot="{ navigate }" v-if="isBannerIndex(banner.id)" :key="banner.id">
            <div @click="navigate" class="banner" :style="{'background-image':`url(${banner.imgUrl})`}"></div>
          </router-link>
        </template>
      </transition>
      <div id="imgPlay" style="left: 1371.5px;">
        <span v-for="banner in banners" :key="banner.id" :class="{'on':isBannerIndex(banner.id)}" @click="changeBannerIndex(banner.id)"></span>
      </div>
    </div>
    <div class="scroll">
      <b class="b1"></b>
      <b class="b2 d1"></b>
      <b class="b3 d1"></b>
      <b class="b4 d1"></b>
      <div class="b scrollInfo">The Norwegian, speaking exclusively to ManUtd.com ahead of the Red Heart Legends match with Real Madrid on 2 June.</div>
      <b class="b4b d1"></b>
      <b class="b3b d1"></b>
      <b class="b2b d1"></b>
      <b class="b1b"></b>
    </div>
    <div class="block1">
      <div class="numbers">
        <b class="b1"></b>
        <b class="b2 d1"></b>
        <b class="b3 d1"></b>
        <b class="b4 d1"></b>
        <div class="b numbersInfo">
          <div class="numbersInfoNewest">
            <span class="numbersInfoTitle">Winning Numbers&nbsp;&nbsp;</span>
            <font>{{currentIssueNo}}</font>
            <br>
            <div id="historyResultWait" class="homeResultWait" :style="!drawNumbers.length ? 'display:block' : ''">Waiting for the lottery!</div>
            <div id="historyResult" class="numbersInfoBall" v-if="drawNumbers.length">
              <span class="ball3" v-for="n in drawNumbers">
                {{n}}
              </span>
            </div>
          </div>
          <div>
            <span class="numbersInfoTitle">Next Lottery Time:</span>
            <font>{{formattedNextIssueNoDateTime}}</font>
          </div>
          <div class="numbersInfoNext">
            <span v-for="s in secondParts" class="count1">
              {{s}}
            </span>
          </div>
          <div>
            <span class="numbersInfoTitle">Current Estimated Jackpot:</span>
            <br>
            <strong>${{jackPot}}</strong>
          </div>
        </div>
        <b class="b4b d1"></b>
        <b class="b3b d1"></b>
        <b class="b2b d1"></b>
        <b class="b1b"></b>
      </div>
      <div class="stages">
        <b class="b1"></b>
        <b class="b2 d1"></b>
        <b class="b3 d1"></b>
        <b class="b4 d1"></b>
        <div class="b stagesInfo">
          <div class="clear stagesTitle">
            <span class="left">
              <strong>　Stages</strong>
            </span>
            <span class="right">
              <strong>Numbers　</strong>
            </span>
          </div>
          <div class="clear lineX line1"></div>
          <div class="stagesResult" v-for="(s,i) in stages" :key="s.issueNo" :class="i%2===0?'stagesBackground':''">
            <font class="left">&nbsp;&nbsp;{{s.issueNo}}</font>
            <span class="right">
              {{s.drawNumbers.map(x=>parseInt(x)).join(' ')}}
              <!--去0 +空白-->
            </span>
          </div>
          <div class="clear lineX line2"></div>
          <div align="center">
            <router-link :to="{ name: 'History' }">
              <img src="../assets/images/button1.jpg">
            </router-link>
            <br>
            <br>
            <br>
          </div>
          <div align="center">
            <input type="text" class="stagesInput" v-model="issueNo">&nbsp;
            <img src="../assets/images/button2.jpg" class="hand" @click="search">
            <br>
            <br>
          </div>
        </div>
        <b class="b4b d1"></b>
        <b class="b3b d1"></b>
        <b class="b2b d1"></b>
        <b class="b1b"></b>
      </div>
      <div class="related">
        <b class="b1"></b>
        <b class="b2 d1"></b>
        <b class="b3 d1"></b>
        <b class="b4 d1"></b>
        <div class="b relatedInfo">
          <div class="clear relatedTitle">
            <span class="left">
              <strong>　Related Information</strong>
            </span>
            <span class="right">
              <router-link :to="{name:'News'}">
                More　
              </router-link>
            </span>
          </div>
          <div class="clear lineX line1"></div>
          <div class="relatedList">
            <li v-for="news in topNews" :key="news.id">
              <router-link :to="{ name:'NewsDetail', params:{ id:news.id }}">
                {{news.title}}
              </router-link>
            </li>
          </div>
        </div>
        <b class="b4b d1"></b>
        <b class="b3b d1"></b>
        <b class="b2b d1"></b>
        <b class="b1b"></b>
        <div class="relatedAd">
          <div>
            <a href="http://www.ctlottery.org/Modules/Scratch/default.aspx?id=1174" target="_blank">
              <img src="../assets/images/home_1.png" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="block2">
      <div class="qas">
        <b class="b1"></b>
        <b class="b2 d1"></b>
        <b class="b3 d1"></b>
        <b class="b4 d1"></b>
        <div class="b qasInfo">
          <div class="clear relatedTitle">
            <span class="left">
              <strong>　Related Information</strong>
            </span>
            <span class="right">
              <router-link :to="{name:'Faq'}">
                More　
              </router-link>
            </span>
          </div>
          <div class="clear lineX line1"></div>
          <div class="qasList">
            <div class="qasQuestion">
              <router-link :to="{name:'Faq'}">
                Q：Does the betting center open on weekend days?
              </router-link>
            </div>
            <div class="qasAnswer">
              <router-link :to="{name:'Faq'}">
                A：Yes,we open seven days a week.
              </router-link>
            </div>
            <div class="qasQuestion">
              <router-link :to="{name:'Faq'}">
                Q：how do you use the money from lottery selling?
              </router-link>
            </div>
            <div class="qasAnswer">
              <router-link :to="{name:'Faq'}">
                Q：50%of our money will use in awarding, 10% will use in lottery issuing andmaintenance, 40% will donate to the UNICEF.
              </router-link>
            </div>
          </div>
        </div>
        <b class="b4b d1"></b>
        <b class="b3b d1"></b>
        <b class="b2b d1"></b>
        <b class="b1b"></b>
      </div>
      <div class="links">
        <b class="b1"></b>
        <b class="b2 d1"></b>
        <b class="b3 d1"></b>
        <b class="b4 d1"></b>
        <div class="b linksInfo">
          <table width="80%" border="0" align="center" cellpadding="0" cellspacing="1">
            <tbody>
              <tr>
                <td align="center">
                  <a href="https://www.lottomatica.it/" target="_blank">
                    <img src="../assets/images/home_2.png" alt="">
                  </a>
                </td>
                <td align="center">
                  <a href="http://www.illinoislotterylive.com/" target="_blank">
                    <img src="../assets/images/home_3.png" alt="">
                  </a>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <a href="http://www.megamillions.com/" target="_blank">
                    <img src="../assets/images/home_4.png" alt="">
                  </a>
                </td>
                <td align="center">
                  <a href="http://www.powerball.com/" target="_blank">
                    <img src="../assets/images/home_5.jpg" alt="">
                  </a>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <a href="http://www.calottery.com/" target="_blank">
                    <img src="../assets/images/home_6.png" alt="">
                  </a>
                </td>
                <td align="center">
                  <a href="http://www.lottery.ie/" target="_blank">
                    <img src="../assets/images/home_7.png" alt="">
                  </a>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <a href="http://www.rilot.com/" target="_blank">
                    <img src="../assets/images/home_8.png" alt="">
                  </a>
                </td>
                <td align="center"><a href="https://mylotto.co.nz/" target="_blank">
                    <img src="../assets/images/home_9.png" alt="">
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <b class="b4b d1"></b>
      <b class="b3b d1"></b>
      <b class="b2b d1"></b>
      <b class="b1b"></b>
    </div>
  </div>
</template>

<script>
import { issueNo } from '../mixins';
import service from '../service';
import event from '../event';

export default {
  mixins: [issueNo],
  data() {
    return {
      bannerIndex: 0,
      topNews: [],
      stages: [],
      changeBannerIndexTimerId: null,
      issueNo: ''
    }
  },
  methods: {
    getTopNews() {
      this.topNews = service.getTopNews();
    },
    async getTop5Async() {
      let result = await service.getTop5Async();
      this.stages = result;
    },
    changeBannerIndex(index) {
      this.bannerIndex = index;
      this.initChangeBannerIndexTimerId();
    },
    isBannerIndex(index) {
      return this.bannerIndex === index;
    },
    initChangeBannerIndexTimerId() {
      clearTimeout(this.changeBannerIndexTimerId);

      this.changeBannerIndexTimerId = setTimeout(() => {
        let nextIndex = this.bannerIndex + 1;
        if (nextIndex + 1 > this.banners.length)
          nextIndex = 0;

        this.changeBannerIndex(nextIndex);
      }, 8000);
    },
    search() {
      this.$router.push({ name: 'History', query: { issueNo: this.issueNo } });
    }
  },
  created() {
    this.getTopNews();
    this.initChangeBannerIndexTimerId();
    this.getTop5Async();

    event.receive(event.eventNames.newIssue, this.getTop5Async);
  },
  beforeDestroy() {
    clearTimeout(this.changeBannerIndexTimerId);
    event.unsubscribe(event.eventNames.newIssue, this.getTop5Async);
  },
  computed: {
    banners() {
      return [
        { id: 0, imgUrl: '/images/banners/home_banner_1.jpg', toName: 'Rules' },
        { id: 1, imgUrl: '/images/banners/home_banner_2.jpg', toName: 'History' },
        { id: 2, imgUrl: '/images/banners/home_banner_3.jpg', toName: 'Home' }
      ];
    }
  }
}
</script>

<style scoped>
.fade-banner-enter-active {
  transition: opacity 1s;
}
.fade-banner-enter {
  opacity: 0;
}
</style>