import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Game from '../views/Game.vue';
import Rules from '../views/Rules.vue';
import Faq from '../views/Faq.vue';
import News from '../views/News.vue';
import NewsDetail from '../views/NewsDetail.vue';
import History from '../views/History.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Lucky Airship'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About Lucky Airship'
    }
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
    meta: {
      title: 'Background of the Lucky Airship'
    }
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
    meta: {
      title: 'Rules'
    }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      title: 'Lucky Airship Lottery history'
    },
    props: route => ({ issueNo: route.query.issueNo })
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      title: 'FAQ'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      title: 'Lucky Airship News'
    }
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: NewsDetail,
    meta: {
      title: 'Lucky Airship News'
    },
    props: route => ({
      id: route.params.id
    })
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
