
import Pager from './Pager';
import DatePicker from './DatePicker';

export default {
    install: app => {
        app.component('c-pager', Pager);
        app.component('c-dp', DatePicker);
    }
};

