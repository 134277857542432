<template>
  <div class="paginator" v-if="total">
    <a v-if="activePrev" class="pagelink" href="javascript:void(0);" style="width:45px;" @click="goToPrev">
      Previous
    </a>
    <template v-for="p in itemsPerPage">
      <a href="javascript:void(0);" :class="getPagerItemNumber(p)===value?'nolink':'pagelink'" v-if="getPagerItemNumber(p)<=totalPages" @click="goToPage(getPagerItemNumber(p))">
        {{getPagerItemNumber(p)}}
      </a>
    </template>
    <a v-if="activeNext" href="javascript:void(0);" class="pagelink" style="width:45px;" @click="goToNext">
      Next
    </a>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      itemsPerPage: 5,
      jumpPageNumber: null
    };
  },
  methods: {
    getPagerItemNumber(number) {
      return this.pageBlock * this.itemsPerPage + number;
    },
    goToPage(pageNumber) {
      if (this.value === pageNumber) return;

      if (isNaN(parseInt(pageNumber)) || parseInt(pageNumber) > this.totalPages || parseInt(pageNumber) < 1)
        return;

      this.emitEvent(pageNumber);
    },
    goToPrev() {
      if (!this.activePrev) return;
      this.goToPage(this.value - 1);
    },
    goToNext() {
      if (!this.activeNext) return;
      this.goToPage(this.value + 1);
    },
    emitEvent(pageNumber) {
      this.$emit('input', pageNumber);
      this.$emit('change', pageNumber);
    }
  },
  computed: {
    activePrev() {
      return this.value > 1;
    },
    activeNext() {
      return this.value < this.totalPages;
    },
    totalPages() {
      return Math.floor((this.total + this.pageSize - 1) / this.pageSize);
    },
    pageBlock() {
      return Math.floor((this.value - 1) / this.itemsPerPage);
    }
  }
};
</script>