<template>
  <div class="body">
    <div class="clear location">
      <router-link :to="{name:'Home'}">
        Home
      </router-link>
      &gt; History
    </div>
    <div class="clear lineX lineMargin"></div>
    <div>
      <b class="b1"></b>
      <b class="b2 d1"></b>
      <b class="b3 d1"></b>
      <b class="b4 d1"></b>
      <div class="b">
        <div class="content">
          <div class="contentLeft2">
            <div class="history">
              <div class="historyResult" :style="search.isLoading ? 'opacity:.5;pointer-events: none;':''">
                <span class="historyResultSpan">Winning Numbers&nbsp;&nbsp;</span>
                <font>{{currentIssueNo}}</font>
                <div id="historyResultWait" class="historyResultWait" :style="!drawNumbers.length ? 'display:block' : ''">Waiting for the lottery!</div>
                <div id="historyResult" v-if="drawNumbers.length">
                  <div>
                    <span class="ball3" v-for="n in drawNumbers">
                      {{n}}
                    </span>
                  </div>
                  <div>
                    <span class="historyResultSpan">Current Estimated Jackpot:</span><br>
                    <strong>${{jackPot}}</strong>
                  </div>
                </div>
                <div class="clear lineX"></div>
                <div class="historySearch">
                  Issue:<input type="text" class="historyInput" v-model="search.issueNo">&nbsp;

                  <img src="../assets/images/button2.jpg" @click="searchAsync">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Date:<c-dp v-model="search.date" class="historyInput date"></c-dp>
                </div>
                <div class="historyTitle">
                  <span>History records:</span>
                </div>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <th>
                        Date
                      </th>
                      <th>
                        Installments
                      </th>
                      <th>
                        Numbers
                      </th>
                    </tr>
                    <tr v-for="(item, i) in history" :key="item.issueNo" :class="i%2===0?'historyTableBg':''">
                      <td>{{item.issueEndTime | formatDateTime('YYYY-MM-DD')}}</td>
                      <td>{{item.issueNo}}</td>
                      <td>
                        <span v-for="n in item.drawNumbers" class="ball1">
                          {{parseInt(n)}}
                          <!-- 去掉0-->
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="clear lineX historyAd"></div>
                <div class="paginator historyAd">
                  <c-pager v-model="search.pageNumber" :total="search.totalCount" :pageSize="search.pageSize" @change="pageChange"></c-pager>
                </div>
              </div>
            </div>
          </div>
          <div class="contentRight2">
            <div class="historyResult">
              <span>
                Next Lottery(<font>{{nextIssueNo}}</font>):&nbsp;&nbsp;
                <font>{{formattedNextIssueNoDateTime}}</font>
                <div class="historyResultDate">
                  <span v-for="s in secondParts" class="count1">
                    {{s}}
                  </span>
                </div>
              </span>
            </div>
            <div class="clear lineX historyAd"></div>
            <div class="historyAd">
              <a href="https://ga.secondchancebonuszone.com/playitagain/" target="_blank">
                <img src="../assets/images/history_1.png" alt="">
              </a>
            </div>
            <div class="historyAd">
              <a href="http://www.galottery.com/games/draw-games/powerball" target="_blank">
                <img src="../assets/images/history_2.png" alt="">
              </a>
            </div>
            <div class="historyAd"></div>
            <div class="newsDetailTilte">
              <div class="left">Related Information</div>
              <div class="right">
                <router-link :to="{name:'News'}">
                  <img src="../assets/images/more.jpg">
                </router-link>
              </div>
            </div>
            <div class="newsDetailList">
              <template v-for="news in newsList">
                <li :key="news.id + 'li'">
                  <router-link :to="{ name:'NewsDetail', params:{ id:news.id }}" :title="news.title">
                    {{news.title}}
                  </router-link>
                </li>
                <div class="clear lineX" :key="news.id + 'div'"></div>
              </template>
              <div class="clear lineX"></div>
            </div>
          </div>
        </div>
      </div>
      <b class="b4b d1"></b>
      <b class="b3b d1"></b>
      <b class="b2b d1"></b>
      <b class="b1b"></b>
    </div>
  </div>
</template>

<script>
import { issueNo } from '../mixins';
import service from '../service';

export default {
  props: {
    issueNo: {
      type: String
    }
  },
  mixins: [issueNo],
  data() {
    return {
      newsList: [],
      history: [],
      search: {
        isLoading: false,
        date: '',
        issueNo: '',
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
      }
    }
  },
  methods: {
    getNewsList() {
      this.newsList = service.getNews(1, 4).data;
    },
    async searchAsync() {
      if (this.search.isLoading)
        return;

      this.search.pageNumber = 1;

      await this.getHistoryAsync();
    },
    async getHistoryAsync() {
      if (this.search.isLoading)
        return;

      this.search.isLoading = true;
      let result = await service.searchAsync(this.search.issueNo, this.$dateToUtc(this.search.date), this.search.pageSize, (this.search.pageNumber - 1) * this.search.pageSize);
      this.search.isLoading = false;

      this.history = result.collection;
      this.search.totalCount = result.totalCount;
    },
    pageChange() {
      this.getHistoryAsync();
    }
  },
  created() {
    this.search.issueNo = this.issueNo;
    this.getNewsList();
    this.searchAsync();
  }
}
</script>