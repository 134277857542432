import event from '../event';
import service from '../service';

const issueNo = {
    data() {
        return {
            currentIssueNo: '',
            nextIssueNo: '',
            nextIssueDateTime: '',
            drawNumbers: [],
            seconds: null,
            countdownTimerId: null,
            issueNoCountdownTimerId: null,
            jackPot: null
        };
    },
    methods: {
        async getIssueNoAsync() {
            return new Promise(async (resolve) => {
                var fetchAsync = async () => {
                    var isInitLoad = !this.currentIssueNo;
                    var response = await service.getCurrentIssueAsync();

                    this.currentIssueNo = response.currentIssueNo;
                    this.nextIssueNo = response.nextIssueNo;
                    this.nextIssueDateTime = response.nextIssueDateTime;
                    this.seconds = response.seconds;
                    this.drawNumbers = response.drawNumbers;

                    this.countdown();

                    if (response.drawNumbers && response.drawNumbers.length) {
                        if (!isInitLoad) {
                            // 代表更新一期且原先已有獎號不是第一次載入
                            event.emit(event.eventNames.newIssue);
                        }

                        return true;
                    }
                    else {
                        return false;
                    }
                };

                if (await fetchAsync()) {
                    resolve();
                }
                else {
                    clearInterval(this.issueNoCountdownTimerId);
                    this.issueNoCountdownTimerId = setInterval(async () => {
                        if (await fetchAsync()) {
                            clearInterval(this.issueNoCountdownTimerId);
                            resolve();
                        }
                    }, 1000);
                }
            });
        },
        async getJackPotAsync() {
            let result = await service.getJackPotAsync();

            var lkapk10JackPot = result.filter(x => x.name === 'LKAPK10')[0];

            if (lkapk10JackPot)
                this.jackPot = lkapk10JackPot.value;
        },
        countdown() {
            clearInterval(this.countdownTimerId);

            this.countdownTimerId = setInterval(() => {
                if (this.seconds > 0) {
                    this.seconds--;
                }
                else {
                    clearInterval(this.countdownTimerId);
                    this.getIssueNoAsync();
                }
            }, 1000);
        }
    },
    created() {
        this.getIssueNoAsync();
        this.getJackPotAsync();
    },
    beforeDestroy() {
        clearInterval(this.countdownTimerId);
        clearInterval(this.issueNoCountdownTimerId);
    },
    computed: {
        secondParts() {
            if (this.seconds === null || this.seconds === undefined || this.seconds > 9999)
                return [];

            let text = '';

            if (this.seconds < 10) text = '00' + this.seconds;
            else if (this.seconds < 100) text = '0' + this.seconds;
            else text = this.seconds.toString();

            return text.split('');
        },
        formattedNextIssueNoDateTime() {
            return this.$formatDateTime(this.nextIssueDateTime, 'YYYY/MM/DD HH:mm:ss');
        }
    }
};

export default issueNo;