<template>
  <div>
    <div class="head">
      <div class="headContent">
        <div class="headLogo">
          <router-link :to="{name:'Home'}">
            <img src="./assets/images/logo.jpg">
          </router-link>
        </div>
        <div class="headNavigation">
          <div class="headNavigationTop"></div>
          <div class="clear">
            <router-link custom v-for="tab in tabs" :key="tab.displayText" :to="{name:tab.toName }" v-slot="{ navigate, href,isExactActive }">
              <div :class="isExactActive ? tab.exactActiveClass:tab.class" @mouseover="tabOnMouseHandler($event, tab.mouseOverClass)" @mouseout="tabOnMouseHandler($event,isExactActive ? tab.exactActiveMouseOutClass:tab.mouseOutClass)">
                <a :href="href" @click="navigate">
                  {{tab.displayText}}
                </a>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div class="foot">
      <div class="footContent">
        <router-link :to="{ name: 'Home'}">
          About Lucky Airship
        </router-link>
        ｜
        <router-link :to="{ name: 'Game'}">
          Game Background
        </router-link>
        ｜
        <router-link :to="{ name: 'Faq'}">
          FAQ
        </router-link>
        ｜
        <router-link :to="{ name: 'Rules'}">
          Rules
        </router-link>
        <br>
        All winning tickets must be redeemed in the state/jurisdiction in which they are sold. <br>
        Copyright© 1997-2012 Multi-State Lottery Association. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import './assets/css/global.css';
import './assets/css/other.css';

export default {
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Lucky Airship';
      }
    },
  },
  methods: {
    tabOnMouseHandler(e, className) {
      e.target.parentElement.className = className;
    }
  },
  computed: {
    tabs() {
      return [
        {
          displayText: 'Home',
          toName: 'Home',
          exactActiveClass: 'headNavigationButtonSelect',
          class: 'headNavigationButton',
          mouseOverClass: 'headNavigationButtonSelect',
          mouseOutClass: 'headNavigationButton',
          exactActiveMouseOutClass: 'headNavigationButtonSelect'
        },
        {
          displayText: 'About Luckairship',
          toName: 'About',
          exactActiveClass: 'headNavigationButtonSelect2',
          class: 'headNavigationButton2',
          mouseOverClass: 'headNavigationButtonSelect2',
          mouseOutClass: 'headNavigationButton2',
          exactActiveMouseOutClass: 'headNavigationButtonSelect2'
        },
        {
          displayText: 'Game background',
          toName: 'Game',
          exactActiveClass: 'headNavigationButtonSelect2',
          class: 'headNavigationButton2',
          mouseOverClass: 'headNavigationButtonSelect2',
          mouseOutClass: 'headNavigationButton2',
          exactActiveMouseOutClass: 'headNavigationButtonSelect2'
        },
        {
          displayText: 'Rules',
          toName: 'Rules',
          exactActiveClass: 'headNavigationButtonSelect',
          class: 'headNavigationButton',
          mouseOverClass: 'headNavigationButtonSelect',
          mouseOutClass: 'headNavigationButton',
          exactActiveMouseOutClass: 'headNavigationButtonSelect'
        },
        {
          displayText: 'Lottery history',
          toName: 'History',
          exactActiveClass: 'headNavigationButtonSelect2',
          class: 'headNavigationButton2',
          mouseOverClass: 'headNavigationButtonSelect2',
          mouseOutClass: 'headNavigationButton2',
          exactActiveMouseOutClass: 'headNavigationButtonSelect2'
        }
      ];
    }
  }
}
</script>