<template>
  <input readonly>
</template>

<script>
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/themes/base/theme.css';

const $ = require('jquery');

export default {
  props: {
    value: {
      type: String
    }
  },
  watch: {
    value(value) {
      $(this.$el).val(value);
    }
  },
  mounted() {
    $(this.$el).datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: (date) => {
        this.$emit('input', date);
      }
    });
  },
  beforeDestroy() {
    $(this.$el).datepicker('hide').datepicker('destroy');
  }
}
</script>