import Vue from 'vue';

const eventNames = Object.freeze({
    newIssue: 'EVENT_NEW_ISSUE', // 刷新期號
});

const eventBus = new Vue();

const emit = (eventName, ...args) => eventBus.$emit(eventName, ...args);
const receive = (eventName, callbackFn) => eventBus.$on(eventName, callbackFn);
const unsubscribe = (eventName, callbackFn) => eventBus.$off(eventName, callbackFn);

export default { eventNames, emit, receive, unsubscribe }