<template>
  <div class="body">
    <div class="clear location">
      <router-link :to="{name:'Home'}">
        Home
      </router-link>
      &gt; News
    </div>
    <div class="clear lineX lineMargin"></div>
    <div>
      <b class="b1"></b>
      <b class="b2 d1"></b>
      <b class="b3 d1"></b>
      <b class="b4 d1"></b>
      <div class="b">
        <div class="content">
          <div class="contentLeft3">
            <div class="newsList">
              <div class="newsListTop">
                <div class="newsListTopAd" id="newsListTopAd">
                  <img src="../assets/images/news_1.jpg" alt="">
                </div>
                <div class="newsListTopRight">
                  <template v-if="topOneNews">
                    <div class="newsListTopTitle">
                      <router-link :to="{ name:'NewsDetail', params:{ id:topOneNews.id }}" style="font-size:18px">
                        {{topOneNews.title}}
                      </router-link>
                    </div>
                    <div class="newsListTopContent">
                      {{topOneNews.description}}
                    </div>
                  </template>
                </div>
              </div>
              <div class="newsListList">
                <li v-for="news in newsList" :key="news.id">
                  <router-link :to="{ name:'NewsDetail', params:{ id:news.id }}" style="font-size:18px">
                    {{news.title}}
                  </router-link>
                  <span>{{news.description}}</span>
                </li>
              </div>
              <div class="clear lineX lineMargin"></div>
              <c-pager v-model="pageNumber" :total="totalCount" :pageSize="pageSize" @change="pageChange"></c-pager>
            </div>
          </div>
          <div class="contentRight3">
            <div>
              <a href="http://www.ctlottery.org/Modules/Scratch/default.aspx?id=1171">
                <img src="../assets/images/news_2.png" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
      <b class="b4b d1"></b>
      <b class="b3b d1"></b>
      <b class="b2b d1"></b>
      <b class="b1b"></b>
    </div>
  </div>
</template>

<script>
import service from '../service';

export default {
  data() {
    return {
      newsList: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0
    }
  },
  methods: {
    getNews() {
      let result = service.getNews(this.pageNumber, this.pageSize);
      this.newsList = result.data;
      this.totalCount = result.totalCount;
    },
    pageChange() {
      this.getNews();
    }
  },
  created() {
    this.getNews();
  },
  computed: {
    topOneNews() {
      return this.newsList[0];
    }
  }
}
</script>